<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('vat-modal', {id: 0},shown,{width: '800px'})"
                        variant="primary"
                        class="mb-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">

                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.vat.label.id') }}</b-th>
                            <b-th>{{ $t('settings.vat.label.value') }}</b-th>
                            <b-th>{{ $t('settings.vat.label.default') }}</b-th>
                            <b-th>{{ $t('settings.vat.label.actions') }}</b-th>
                        </b-thead>
                        <tbody>
                        <b-tr v-for="vat in vats" :key="vat.id">
                            <b-td>{{ vat.id }}</b-td>
                            <b-td>{{ vat.value }}</b-td>
                            <b-td>{{ vat.is_default ? $t('common.button.yes') : $t('common.button.no') }}</b-td>
                            <b-td>
                                <b-button
                                    @click="$root.$children[0].openModal('vat-modal', {id: vat.id},shown,{width: '800px'})"
                                    size="sm"
                                    variant="primary"
                                    class="mr-2"
                                    :title="$t('common.title.edit')"
                                >
                                    <font-awesome-icon icon="edit"/>
                                </b-button>

                                <b-button @click="deleteVat(vat.id)"
                                          size="sm"
                                          variant="danger"
                                          :title="$t('common.title.delete')"
                                >
                                    <font-awesome-icon icon="trash"/>
                                </b-button>
                            </b-td>
                        </b-tr>
                        </tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SettingsVats',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            vats: [],
        }
    },
    methods: {
        ...mapGetters('Vats', ['getVats']),
        shown() {
            const vatsFetch = this.$store.dispatch('Vats/fetchVats')

            Promise.all([vatsFetch])
                .then(() => {
                    this.vats = this.getVats()
                })
                .finally(() => {
                    this.loading = false
                })
            this.$root.afterSettingsUpdate()
        },
        deleteVat(id) {
            this.$removeConfirm('Vats/deleteVat', id, this.shown)
        },
    },
    mounted() {
        this.shown()
    }
}
</script>